import React from "react";
import TemplateWrapper from "../../layouts";
import { MarginExcluder } from "../../components/styled-components/structures";
import {
  SuperHeading,
  Opening,
  TextList,
  TextListItem,
  Paragraph,
} from "../../components/styled-components/elements";

const WhatWeSing = () => (
  <TemplateWrapper>
    <MarginExcluder>
      <SuperHeading>What We Sing</SuperHeading>
      <Opening>
        We sing a variety of songs, some with backing tracks, some a cappella.
        For example:
      </Opening>
      <TextList>
        {[
          "Walking on Sunshine",
          "Can You Feel the Love Tonight?",
          "This is Me (The Greatest Showman)",
          "It is Well With My Soul",
          "I Dreamed a Dream (Les Miserables)",
          "On My Own (Les Miserables)",
          "Something Inside So Strong",
          "You've Got a Friend",
          "Annie's Song",
          "Hallelujah",
          "The Lord is My Shepherd",
          "You Raise Me Up",
        ].map((song) => {
          return <TextListItem key={song}>{song}</TextListItem>;
        })}
      </TextList>
      <Paragraph>
        ...not to mention a large selection of carols for Christmas! We learn
        new songs every term - all members, new and old learn together and can
        attend as many choirs as they wish.
      </Paragraph>
    </MarginExcluder>
  </TemplateWrapper>
);

export default WhatWeSing;
